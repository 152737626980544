<template>
  <NavBar :title="'提现'"></NavBar>
  <div class="content">
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <van-field
          v-model="fromData.name"
          name="name"
          label="姓名"
          placeholder="请输入"
        />
        <van-field
          v-model="fromData.id_card"
          name="id_card"
          label="身份证号"
          placeholder="请输入"
        />
        <van-cell title="银行卡类型" is-link center @click="showBank = true" />
        <van-field
          v-model="fromData.bank_card"
          name="bank_card"
          label="银行卡号"
          placeholder="请输入"
        />
        <van-field
          v-model="fromData.phone"
          name="phone"
          label="预留手机号"
          placeholder="请输入"
        />
        <!-- <van-cell value="" title="验证码" is-link>
          <template #right-icon><span>获取验证码</span></template>
        </van-cell> -->
        <van-field
          v-model="fromData.money"
          name="money"
          label="提现金额"
          @update:model-value="changeMoney"
          :placeholder="'当前可提现¥ ' + this.$state.user.money"
        />
        <van-cell value="" title="手续费" is-link>
          <template #right-icon
            ><span>{{ rate * 100 + '%' }}</span></template
          >
        </van-cell>
        <van-cell value="" title="到账金额" is-link>
          <template #right-icon
            ><span>{{ receivedMoney }}</span></template
          >
        </van-cell>
        <van-cell value="" title="到账时间" is-link>
          <template #right-icon><span>T+1个工作日到账</span></template>
        </van-cell>
      </van-cell-group>
      <div style="text-align: center">
        <button class="btn">提交</button>
      </div>
    </van-form>
    <BankPicker v-show="showBank" @confirm="confirmBank"></BankPicker>
  </div>
</template>

<script>
import { Picker, Form, Field, Cell, CellGroup } from 'vant'
import BankPicker from '../../components/BankPicker/index.vue'

export default {
  name: 'withdrawal',
  components: {
    [CellGroup.name]: CellGroup,
    [Field.name]: Field,
    [Form.name]: Form,
    [Cell.name]: Cell,
    [Picker.name]: Picker,
    BankPicker,
  },
  data() {
    return {
      receivedMoney: 0,
      fromData: {},
      bankList: [],
      showBank: false,
      bank: {},
      rate: 0,
    }
  },
  created() {
    this.$http('/home/config').then((res) => {
      this.rate = parseFloat(res.data.AgentWithdrawal.rate)
    })
  },
  methods: {
    confirmBank(res) {
      this.showBank = false
      if (res) {
        this.bank = res
      }
    },
    changeMoney(money) {
      const bl = (money * this.rate) / 100
      this.receivedMoney = parseFloat(money - bl).toFixed(2)
    },
    onSubmit(data) {
      this.fromData = {
        bank_card: data.bank_card,
        id_card: data.id_card,
        money: data.money,
        name: data.name,
        phone: data.phone,
        bank_code: this.bank.value,
      }
      this.$http({
        url: '/agent/withdrawalCreate',
        method: 'post',
        data: this.$http.adornData(this.fromData),
      }).then(() => {
        this.$notify({ type: 'success', message: '提现成功!' })
        this.showBank = false
        this.fromData = {}
      })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  // padding: 0 32px;
  position: relative;
  height: calc(100% - 55px);
  margin-bottom: 50px;
}
:deep(.van-field__control) {
  text-align: right !important;
}
:deep(.van-cell__title span) {
  color: var(--van-field-label-color);
}
:deep(.van-cell:after) {
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.26);
}
:deep(.van-cell__label) {
  font-size: 14px;
  padding: 0 16px;
  color: rgba(0, 0, 0, 0.85);
}
.btn {
  margin: 20px auto;
  text-align: center;
  width: 311px;
  height: 44px;
  background: linear-gradient(235deg, #f56f4d 0%, #f35450 100%);
  border-radius: 8px 8px 8px 8px;
  border: none;
  color: #fff;
}
</style>
